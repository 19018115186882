body {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  min-width: 320px;
  overflow-x: hidden;
  /* breadcrumb */
  /* breadcrumb */
  /* Heading */
  /* Heading */
  /* Header */
  /* Header */
  /* Slider */
  /* Slider */
  /* Schema module */
  /* Schema module */
}

@media (max-width: 340px) {
  body {
    overflow-x: visible;
  }
}

body input, body input:focus, body .btn, body .btn:focus, body .btn:active:focus, body button {
  outline: none !important;
  box-shadow: none !important;
  text-decoration: none;
}

body a {
  text-decoration: underline;
}

body a:hover, body a:focus, body a:active {
  text-decoration: none;
  outline: none;
}

body h1 {
  font-size: 30px;
  line-height: 30px;
}

body h2 {
  font-size: 29px;
  line-height: 29px;
}

body h3 {
  font-size: 28px;
  line-height: 28px;
}

body h4 {
  font-size: 27px;
  line-height: 27px;
}

body h5 {
  font-size: 26px;
  line-height: 26px;
}

body h6 {
  font-size: 25px;
  line-height: 25px;
}

body p {
  margin-bottom: 25px;
}

body .nopadding {
  padding: 0;
}

body img {
  max-width: 100%;
}

body #content {
  min-height: 500px;
}

body select {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none !important;
  background-image: url("../image/select-bg.png") !important;
  background-repeat: no-repeat;
  background-position: center right;
  padding-right: 30px !important;
}

body select:focus {
  outline: none;
}

body select::-ms-expand {
  display: none;
}

body .form-control {
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 3px;
}

body .form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body input.form-control, body select.form-control {
  height: 30px;
}

body .btn {
  border-radius: 3px;
  font-weight: bold;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  letter-spacing: 1px;
  min-height: 30px;
  font-size: 15px;
}

body .btn-default-light {
  font-weight: 400;
  letter-spacing: 0;
  padding: 4px 5px;
  font-size: 15px;
}

body .btn-round {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  min-height: 28px;
  padding: 0;
  line-height: 27px;
}

body .btn-remove {
  padding: 8px 0;
  background: transparent;
}

body .btn-remove .fa {
  font-size: 18px;
}

body .btn-upper {
  text-transform: uppercase;
  padding: 4px 7px;
  min-width: 128px;
  line-height: 20px;
}

body .btn-submit, body .btn-light {
  padding: 4px 15px;
}

body .radio-label label, body .checkbox-label label {
  padding-left: 30px;
  padding-right: 15px;
  position: relative;
  font-weight: 400;
}

body .radio-label label input, body .checkbox-label label input {
  opacity: 0;
  position: absolute;
}

body .radio-label label input:checked + .check:before, body .checkbox-label label input:checked + .check:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

body .radio-label label input:disabled + .check, body .checkbox-label label input:disabled + .check {
  cursor: not-allowed;
  opacity: .3;
}

body .radio-label label .check:before, body .checkbox-label label .check:before {
  content: '';
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  left: 4px;
  top: 7px;
  border-radius: 50%;
  z-index: 1;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

body .radio-label label .check:after, body .checkbox-label label .check:after {
  content: '';
  width: 15px;
  height: 15px;
  border-width: 1px;
  border-style: solid;
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  border-radius: 50%;
}

body .radio-label label a, body .radio-label label b, body .checkbox-label label a, body .checkbox-label label b {
  font-weight: 400;
  text-decoration: none;
}

body .checkbox-label label .check {
  font: normal normal normal 12px/1 FontAwesome;
}

body .checkbox-label label .check:before {
  content: "\f00c";
  background: transparent;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -ms-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  left: 1px;
  top: 5px;
  background-color: transparent !important;
}

body .checkbox-label label .check:after {
  border-radius: 2px;
}

body div.required .control-label:after {
  content: '* ';
  color: #F00;
  padding-left: 2px;
}

body .cart-svg {
  margin: 4px;
}

body .breadcrumb {
  border-radius: 0;
  padding: 4px 10px;
  margin-bottom: 60px;
}

body .breadcrumb > li > a, body .breadcrumb > li > span {
  font-size: 13px;
  text-decoration: none;
}

body .breadcrumb > li + li:before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 10px;
  padding: 0 10px;
}

body .heading {
  font-family: "Yeseva One", cursive;
  padding-bottom: 30px;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

body .heading.heading-border {
  margin-bottom: 30px;
}

@media (max-width: 375px) {
  body .heading {
    font-size: 23px;
  }
}

body .col-2 .heading {
  text-align: left;
}

body .col-2 .heading.heading-border {
  border-bottom: none;
  margin-bottom: 10px;
}

body .col-2 .heading.heading-module {
  text-align: center;
}

body .dop-text {
  padding-bottom: 15px;
}

body .heading-module {
  padding-bottom: 50px;
  position: relative;
}

body .heading-module:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  top: 12px;
  z-index: 1;
}

body .heading-module span {
  position: relative;
  padding: 0 55px;
  z-index: 2;
}

@media (max-width: 767px) {
  body .heading-module {
    line-height: 33px;
  }
  body .heading-module:after {
    display: none;
  }
  body .heading-module span {
    padding: 0;
  }
}

body #column-left .heading-module, body #column-right .heading-module {
  line-height: 33px;
  padding-bottom: 30px;
}

body #column-left .heading-module:after, body #column-right .heading-module:after {
  display: none;
}

body #column-left .heading-module span, body #column-right .heading-module span {
  padding: 0;
}

@media (min-width: 992px) and (max-width: 1200px) {
  body #column-left .heading-module span, body #column-right .heading-module span {
    font-size: 23px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body #column-left .heading-module span, body #column-right .heading-module span {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  body header {
    padding-top: 70px;
    padding-bottom: 0;
  }
}

body .navbar-nav {
  padding: 20px 15px;
}

body .navbar-nav > li > a, body .navbar-nav > li > span {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  padding-top: 3px;
  padding-bottom: 0;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
}

body .navbar-nav > li > a:focus, body .navbar-nav > li > span:focus {
  background-color: transparent;
}

@media (min-width: 992px) and (max-width: 1200px) {
  body .navbar-nav > li > a, body .navbar-nav > li > span {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 851px) and (max-width: 991px) {
  body .navbar-nav > li > a, body .navbar-nav > li > span {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  body .navbar-nav > li > a, body .navbar-nav > li > span {
    padding-left: 16px;
    padding-right: 16px;
  }
}

body .navbar-nav > li > a:after, body .navbar-nav > li > span:after {
  content: '|';
  position: absolute;
  right: -1px;
}

body .navbar-nav > li:last-child a:after {
  display: none;
}

body .navbar-nav > li.has-child .caret-container {
  display: none;
}

body .navbar-nav > li.has-child .sub_menu {
  width: 460px;
  height: 0;
  margin-left: 14px;
  position: absolute;
  overflow: hidden;
  z-index: 99;
  min-width: 200px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

@media (min-width: 992px) and (max-width: 1200px) {
  body .navbar-nav > li.has-child .sub_menu {
    margin-left: 7px;
  }
}

@media (min-width: 851px) and (max-width: 991px) {
  body .navbar-nav > li.has-child .sub_menu {
    width: auto;
    margin-left: 7px;
    margin-top: 20px;
    padding-top: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  body .navbar-nav > li.has-child .sub_menu {
    width: auto;
    margin-left: 7px;
    margin-top: 20px;
    padding-top: 0 !important;
  }
}

body .navbar-nav > li.has-child .sub_menu a {
  text-decoration: none;
  padding: 3px 0;
  display: block;
}

body .navbar-nav > li.has-child .sub_menu .sub_menu_contein {
  padding: 0 15px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

body .navbar-nav > li.has-child .sub_menu .sub_menu_contein:after {
  content: '';
  display: table;
  clear: both;
}

@media (min-width: 992px) {
  body .navbar-nav > li.has-child:hover .sub_menu {
    height: auto;
    padding-top: 20px;
    overflow: visible;
    display: block;
  }
  body .navbar-nav > li.has-child:hover .sub_menu .sub_menu_contein {
    padding: 23px 15px;
  }
}

@media (min-width: 851px) and (max-width: 991px) {
  body .navbar-nav > li.has-child a {
    padding-right: 25px;
  }
  body .navbar-nav > li.has-child .caret-container {
    display: block;
    cursor: pointer;
    position: absolute;
    width: 25px;
    top: 0;
    right: 0;
    text-align: center;
  }
  body .navbar-nav > li.has-child .sub_menu {
    display: none;
  }
  body .navbar-nav > li.has-child .tablet-list {
    display: block !important;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  body .navbar-nav > li.has-child a {
    padding-right: 25px;
  }
  body .navbar-nav > li.has-child .caret-container {
    display: block;
    cursor: pointer;
    position: absolute;
    width: 25px;
    top: 0;
    right: 0;
    text-align: center;
  }
  body .navbar-nav > li.has-child .sub_menu {
    display: none;
  }
  body .navbar-nav > li.has-child .tablet-list {
    display: block !important;
    height: auto;
  }
}

body .navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

body .navbar {
  margin-bottom: 0;
  border-radius: 0;
  border: none;
}

body .btn-cart {
  font-family: "Yeseva One", cursive;
  font-size: 16px;
  font-weight: normal;
  padding: 3px;
}

body .btn-cart .fa {
  display: none;
}

body .btn-cart span > span {
  font-size: 12px;
}

body .logo {
  max-width: 160px;
  max-height: 90px;
  display: inline-block;
}

body .search-top {
  width: 260px;
  position: relative;
}

body .search-top input {
  border: 1px solid transparent;
  border-radius: 3px !important;
}

body .search-top input::placeholder {
  font-size: 13px;
}

body .search-top input:-moz-placeholder {
  font-size: 13px;
}

body .search-top input:-ms-input-placeholder {
  font-size: 13px;
}

body .search-top input::-moz-placeholder {
  font-size: 13px;
}

body .search-top input::-webkit-input-placeholder {
  font-size: 13px;
}

body .search-top .open_search_js {
  display: none;
}

@media (min-width: 768px) and (max-width: 991px) {
  body .search-top {
    width: 30px;
    height: 30px;
  }
  body .search-top .search {
    position: absolute;
    width: 30px;
    right: 0;
    opacity: 0;
    -webkit-transition: width .3s;
    -moz-transition: width .3s;
    -ms-transition: width .3s;
    -o-transition: width .3s;
    transition: width .3s;
  }
  body .search-top.active .search {
    width: 260px;
    opacity: 1;
    z-index: 100;
  }
  body .search-top.active .search input {
    z-index: 100;
    opacity: 1;
  }
  body .search-top.active .open_search_js {
    z-index: 0;
    display: none;
  }
  body .search-top.active .close-mod {
    display: block;
    z-index: 99;
  }
  body .search-top input {
    opacity: 0;
  }
  body .search-top .open_search_js {
    display: block;
  }
}

body .search-top .close-mod {
  display: none;
}

body .close-mod {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

body .btn-search {
  position: absolute;
  z-index: 100;
  background: transparent;
  padding: 0 7px;
  right: 0;
}

body .btn-search .fa {
  font-size: 13px;
}

body .col-header {
  padding: 35px 15px 30px 15px;
}

body .col-logo {
  padding: 5px 15px;
}

body .col-logo #logo {
  height: 85px;
  line-height: 85px;
  display: block;
}

@media (min-width: 767px) {
  body .search-header {
    padding-right: 10px;
  }
}

body .dropdown-menu {
  border-radius: 2px;
  border: none;
}

body .dropdown-cart {
  width: 345px;
  font-size: 13px;
}

body .dropdown-cart .thumb {
  width: 80px;
}

body .dropdown-cart a {
  text-decoration: none;
}

body .dropdown-cart a:hover {
  text-decoration: underline;
}

body .dropdown-cart .btn-remove .fa {
  font-size: 13px;
}

body .dropdown-cart .btn {
  margin: 2px 8px;
  padding: 4px 7px;
}

body .dropdown-cart .btn + .btn {
  margin: 2px 5px;
}

body .dropdown-cart .btn:hover {
  text-decoration: none;
}

body .dropdown-cart table tr > td:first-child {
  padding-left: 15px;
}

body .dropdown-cart table tr > td:last-child {
  padding-right: 4px;
}

body .dropdown-cart .total-text {
  font-size: 15px;
}

body .dropdown-cart p {
  margin-bottom: 13px;
}

@media (max-width: 767px) {
  body .dropdown-cart {
    max-width: 300px;
  }
}

body .dropdown-level-2 {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
}

body .dropdown-level-2 .dropdown-menu-level-2 {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  background: #fff;
  margin-top: 0;
}

@media (max-width: 767px) {
  body .dropdown-level-2 .dropdown-menu-level-2 {
    position: static;
    display: block;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

body .dropdown-level-2 > .btn-menu .fa {
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 12px;
}

@media (max-width: 767px) {
  body .dropdown-level-2 > .btn-menu .fa {
    display: none;
  }
}

@media (max-width: 767px) {
  body .dropdown-level-2 > .btn-menu {
    font-weight: 500;
  }
}

body .dropdown-level-2:hover .dropdown-menu-level-2 {
  display: block;
}

body .dop-menu-dropdown {
  max-width: 330px;
  min-width: 195px;
  margin-top: 8px;
}

body .dop-menu-dropdown > li > a {
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: normal;
}

body .dop-menu-dropdown > li > a > span {
  font-size: 13px;
}

body .dop-menu-dropdown > li .btn-menu {
  display: block;
  padding: 9px 20px;
  width: 100%;
  text-align: left;
  background-color: transparent;
}

body .dop-menu-dropdown > li .btn-menu:hover {
  text-decoration: none;
}

body .col-header .menu-header {
  width: auto;
  display: inline-block;
  padding-top: 8px;
}

body .col-header .menu-header:after {
  width: 100%;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  content: '';
  display: inline-block;
}

body .col-header .menu-header > li {
  padding-right: 70px;
  padding-left: 3px;
}

body .col-header .menu-header > li:last-child {
  padding-right: 0;
}

@media (max-width: 1201px) {
  body .col-header .menu-header > li {
    padding-right: 10px;
  }
}

@media (min-width: 1201px) {
  body .col-header .menu-header {
    min-width: 400px;
    max-width: 570px;
  }
}

body .col-header span.dropdown-toggle {
  text-decoration: underline;
}

body .col-header span.dropdown-toggle:hover {
  text-decoration: none;
  cursor: pointer;
}

body .col-menu {
  height: 36px;
}

body .btn-menu {
  font-weight: 400;
  letter-spacing: 0;
}

@media (max-width: 767px) {
  body #form-currency:after {
    content: '';
    display: table;
    clear: both;
  }
}

@media (max-width: 767px) {
  body .row-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  body .row-header .search-header {
    order: 1;
  }
  body .row-header .dropdown-menu-left {
    right: 0;
    left: auto;
  }
  body .row-header .col-menu {
    flex: 1 1 100px;
    text-align: right;
  }
}

@media (max-width: 767px) {
  body .cart-header {
    width: 100%;
    height: 60px;
    padding: 15px;
    margin-bottom: 30px;
    position: fixed;
    top: 0;
    z-index: 1002;
  }
  body .cart-header > div:first-child {
    float: left;
  }
  body .cart-header > div:last-child {
    float: right;
  }
  body .cart-header .fa {
    font-size: 32px;
  }
}

body .cart-header .cart-empty {
  padding-top: 10px;
}

@media (min-width: 768px) and (max-width: 991px) {
  body .search-header {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  body .search-header {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 30px;
  }
  body .search-header .search-top {
    width: 100%;
  }
}

@media (max-width: 767px) {
  body .main-menu {
    position: fixed;
    z-index: 1001;
    top: 0;
    bottom: 0;
    width: 80%;
    margin-top: 60px;
    border-top: 0 !important;
    padding-top: 0;
    padding-bottom: 40px;
    overflow-x: hidden;
    left: -110%;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    min-width: 260px;
  }
  body .main-menu.active {
    left: 0;
  }
  body .main-menu > div, body .main-menu > div > ul {
    margin-left: 0;
    margin-right: 0;
  }
  body .main-menu .navbar-nav > li > a, body .main-menu .navbar-nav > li > span {
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 55px;
  }
  body .main-menu .navbar-nav > li > a:hover, body .main-menu .navbar-nav > li > a.active, body .main-menu .navbar-nav > li > span:hover, body .main-menu .navbar-nav > li > span.active {
    text-decoration: underline;
  }
  body .main-menu .navbar-nav > li > a:after, body .main-menu .navbar-nav > li > span:after {
    display: none;
  }
  body .main-menu .navbar-nav > li > a.active + .mob-dropdown + .sub_menu, body .main-menu .navbar-nav > li > span.active + .mob-dropdown + .sub_menu {
    display: block;
    height: auto !important;
  }
  body .main-menu .navbar-nav > li.has-child .sub_menu {
    margin-top: 0;
    position: relative;
    max-width: 100%;
    background: transparent;
    box-shadow: none;
    height: auto;
    display: none;
    padding: 0;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -ms-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
  }
  body .main-menu .navbar-nav > li.has-child .sub_menu .sub_menu_contein {
    background: transparent;
  }
  body .main-menu .navbar-nav > li.has-child .sub_menu a {
    color: #fff;
  }
  body .main-menu .navbar-nav > li.has-child .sub_menu a:hover, body .main-menu .navbar-nav > li.has-child .sub_menu a.active {
    text-decoration: underline;
  }
  body .main-menu .navbar-nav > li.has-child .mob-dropdown {
    position: absolute;
    right: 0;
    top: 0;
    width: 35px;
    height: 40px;
    padding: 8px 0;
    text-align: center;
    font-family: FontAwesome;
    font-size: 18px;
  }
  body .main-menu .navbar-nav > li.has-child .mob-dropdown.active:before, body .main-menu .navbar-nav > li.has-child .mob-dropdown.active.active-mob:before {
    content: "\f106";
  }
  body .main-menu .navbar-nav > li.has-child .mob-dropdown.active-mob:before, body .main-menu .navbar-nav > li.has-child .mob-dropdown:before {
    content: "\f107";
    display: inline;
  }
  body .main-menu .navbar-nav > li.has-child:hover .sub_menu, body .main-menu .navbar-nav > li.has-child:hover .sub_menu_contein {
    padding-top: 0;
    padding-bottom: 0;
  }
  body #menu-btn.active .fa:before {
    content: '\f060';
  }
  body .close-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 60px;
    padding: 13px 15px;
    z-index: 99;
    display: none;
  }
  body .close-menu:hover {
    cursor: pointer;
  }
  body .close-menu:hover .fa {
    opacity: .8;
  }
  body .close-menu .fa {
    font-size: 32px;
  }
}

body .close-menu {
  display: none !important;
}

body .slider-cut {
  width: 100%;
  overflow: hidden;
  position: relative;
}

body .slider-cut .item {
  width: 100%;
  overflow: hidden;
  position: relative;
}

body .slider-cut .item img {
  position: absolute;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 1000%;
  max-height: 1000%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  top: 50%;
}

body .schemes-header {
  position: relative;
  padding-right: 50px;
}

body .schemes-header .schemes-container {
  position: absolute;
  top: 5px;
  right: 0;
  padding-top: 35px;
  padding-bottom: 30px;
}

body .schemes-header .schemes-container .schemes {
  display: inline-block;
  cursor: pointer;
}

body .schemes-header .schemes-container .schemes:before {
  content: url("../image/schema.png");
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
}

body .schemes-header .schemes-container ul {
  min-width: 180px;
  margin-top: 15px;
  cursor: pointer;
  padding: 0;
}

body .schemes-header .schemes-container ul li {
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 767px) {
  body .schemes-header {
    padding-right: 40px;
  }
  body .schemes-header .schemes-container .dropdown-menu {
    left: -70px;
  }
}
