$url: '../image/';
$fonts-size: 15px;
$font: 'Roboto', sans-serif;
$font-header: 'Yeseva One', cursive;
body {
  font-family: $font;
  font-size: $fonts-size;
  line-height: 1.5;
  min-width: 320px;
  overflow-x: hidden;
  @media (max-width: 340px) {
    overflow-x: visible;
  }
  input, input:focus, .btn, .btn:focus, .btn:active:focus, button {
    outline: none !important;
    box-shadow: none !important;
    text-decoration: none;
  }
  a {
    text-decoration: underline;
    &:hover, &:focus, &:active {
      text-decoration: none;
      outline: none;
    }
    &:focus, &:active {
    }
  }
  h1 {
    font-size: 30px;
    line-height: 30px;
  }
  h2 {
    font-size: 29px;
    line-height: 29px;
  }
  h3 {
    font-size: 28px;
    line-height: 28px;
  }
  h4 {
    font-size: 27px;
    line-height: 27px;
  }
  h5 {
    font-size: 26px;
    line-height: 26px;
  }
  h6 {
    font-size: 25px;
    line-height: 25px;
  }
  p{
    margin-bottom: 25px;
  }
  .nopadding {
    padding: 0;
  }
  img {
    max-width: 100%;
  }
  #content {
    min-height: 500px;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none !important;
    background-image: url($url + 'select-bg.png') !important;
    background-repeat: no-repeat;
    background-position: center right;
    padding-right: 30px !important;
    &:focus {
      outline: none;
    }
  }
  select::-ms-expand {
    display: none;
  }
  .form-control {
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 3px;
    &:focus {
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  input.form-control, select.form-control {
    height: 30px;
  }
  .btn {
    border-radius: 3px;
    font-weight: bold;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    letter-spacing: 1px;
    min-height: 30px;
    font-size: 15px;
  }
  .btn-default-light{
    font-weight: 400;
    letter-spacing: 0;
    padding: 4px 5px;
    font-size: 15px;
  }
  .btn-round{
    border-radius: 50%;
    width: 28px;
    height: 28px;
    min-height: 28px;
    padding: 0;
    line-height: 27px;
  }
  .btn-remove{
    padding: 8px 0;
    background: transparent;
    .fa{
      font-size: 18px;
    }
  }
  .btn-upper{
    text-transform: uppercase;
    padding: 4px 7px;
    min-width: 128px;
    line-height: 20px;
  }
  .btn-submit, .btn-light{
    padding: 4px 15px;
  }

  .radio-label, .checkbox-label {
    label {
      padding-left: 30px;
      padding-right: 15px;
      position: relative;
      font-weight: 400;
      input {
        opacity: 0;
        position: absolute;
        &:checked {
          & + .check:before {
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
          }
        }
        &:disabled{
          & + .check{
            cursor: not-allowed;
            opacity: .3;
          }
        }
      }
      .check:before {
        content: '';
        width: 7px;
        height: 7px;
        display: block;
        position: absolute;
        left: 4px;
        top: 7px;
        border-radius: 50%;
        z-index: 1;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -ms-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
      }
      .check:after{
        content: '';
        width: 15px;
        height: 15px;
        border-width: 1px;
        border-style: solid;
        display: block;
        position: absolute;
        left: 0;
        top: 3px;
        border-radius: 50%;
      }
      a, b{
        font-weight: 400;
        text-decoration: none;
      }
    }
  }
  .checkbox-label {
    label {
      .check {
        font: normal normal normal 12px/1 FontAwesome;
      }
      .check:before {
        content: "\f00c";
        background: transparent;
        -webkit-transition: 0s;
        -moz-transition: 0s;
        -ms-transition: 0s;
        -o-transition: 0s;
        transition: 0s;
        left: 1px;
        top: 5px;
        background-color: transparent !important;
      }
      .check:after{
        border-radius: 2px;
      }
    }
  }
  div.required .control-label:after {
    content: '* ';
    color: #F00;
    padding-left: 2px;
  }
  .cart-svg{
    margin: 4px;
  }

  /* breadcrumb */
  .breadcrumb {
    border-radius: 0;
    padding: 4px 10px;
    margin-bottom: 60px;
    & > li {
      & > a, & > span {
        font-size: $fonts-size - 2;
        text-decoration: none;
      }
      & + li:before {
        content: "\f054";
        font-family: FontAwesome;
        font-size: 10px;
        padding: 0 10px;
      }
    }
  }
  /* breadcrumb */

  /* Heading */
  .heading {
    font-family: $font-header;
    padding-bottom: 30px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    &.heading-border {
      margin-bottom: 30px;
    }
    @media (max-width:375px) {
        font-size: 23px;
      }
  }
  .col-2{
    .heading {
      text-align: left;
      &.heading-border {
       border-bottom: none;
        margin-bottom: 10px;
      }
      &.heading-module{
        text-align: center;
      }
    }
  }
  .dop-text {
    padding-bottom: 15px;
  }
  .heading-module{
    padding-bottom: 50px;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      top: 12px;
      z-index: 1;
    }
    span{
      position: relative;
      padding: 0 55px;
      z-index: 2;
    }
    @media (max-width: 767px){
      line-height: 33px;
      &:after{
        display: none;
      }
      span{
        padding: 0;
      }
    }
  }
  #column-left, #column-right{
    .heading-module{
      line-height: 33px;
      padding-bottom: 30px;
      &:after{
        display: none;
      }
      span{
        padding: 0;
        @media (min-width: 992px) and (max-width:1200px){
          font-size: 23px;
        }
        @media (min-width: 768px) and (max-width:991px){
          font-size: 18px;
        }
      }
    }
  }
  /* Heading */

  /* Header */
  header{
    @media (max-width:767px){
      padding-top: 70px;
      padding-bottom: 0;
    }
  }
  .navbar-nav{
    padding: 20px 15px;
    &>li{
      &>a, &>span{
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px;
        text-decoration: none;
        padding-top: 3px;
        padding-bottom: 0;
        padding-left: 14px;
        padding-right: 14px;
        position: relative;
        &:focus{
          background-color: transparent;
        }
        @media (min-width: 992px) and (max-width:1200px){      
          padding-left: 16px;
          padding-right: 16px;
        }
        @media (min-width: 851px) and (max-width:991px){ 
          padding-left: 16px;
          padding-right: 16px;
        }
        @media (min-width: 768px) and (max-width:850px){   
          padding-left: 16px;
          padding-right: 16px;
        }
        &:after{
          content: '|';
          position: absolute;
          right: -1px;
        }
      }
      &:first-child{
        &>a{
          @media (min-width: 992px) and (max-width:1200px){

          }
          @media (min-width: 851px) and (max-width:991px){

          }
          @media (min-width: 768px) and (max-width:850px){

          }
        }
      }
      &:last-child{  
        a:after{
          display: none;
        }
      }
      &.has-child{
        .caret-container {
          display: none;

        }
        .sub_menu{
          width: 460px;
          height: 0;
          margin-left: 14px;
          position: absolute;
          overflow: hidden;
          z-index: 99;
          min-width: 200px;
          -webkit-transition: .5s;
          -moz-transition:  .5s;
          -ms-transition:  .5s;
          -o-transition:  .5s;
          transition:  .5s;
        @media (min-width: 992px) and (max-width:1200px){      
          margin-left: 7px;
        }
        @media (min-width: 851px) and (max-width:991px){
          width: auto; 
          margin-left: 7px;
          margin-top: 20px;
          padding-top: 0!important;
        }
        @media (min-width: 768px) and (max-width:850px){
          width: auto;   
          margin-left: 7px;
          margin-top: 20px;
          padding-top: 0!important;
        }
          a{
            text-decoration: none;
            padding: 3px 0;
            display: block;
          }
          .sub_menu_contein{
            padding: 0 15px;
            -webkit-transition: .3s;
            -moz-transition:  .3s;
            -ms-transition:  .3s;
            -o-transition:  .3s;
            transition:  .3s;
            &:after{
              content: '';
              display: table;
              clear: both;
            }
          }
        }
        @media (min-width: 992px){      
            &:hover{
            .sub_menu{
              height: auto;
              padding-top: 20px;
              overflow: visible;
              display: block;
              .sub_menu_contein{
                padding: 23px 15px;
              }
            }
          }
        }
        
        @media (min-width: 851px) and (max-width:991px){ 
            a{
            padding-right: 25px;
          }
          .caret-container {
            display: block;
            cursor: pointer;
            position: absolute;
            width: 25px;
            top: 0;
            right: 0;
            text-align: center;
          }
           .sub_menu {
              display: none;
            }
            .tablet-list {
              display: block!important;
              height: auto;
            }
        }
        @media (min-width: 768px) and (max-width:850px){   
            a{
            padding-right: 25px;
          }
          .caret-container {
            display: block;
            cursor: pointer;
            position: absolute;
            width: 25px;
            top: 0;
            right: 0;
            text-align: center;
          }
          .sub_menu {
              display: none;
            }
          .tablet-list {
              display: block!important;
              height: auto;
          }
        }
      }
    }
  }
  .navbar-collapse{
    padding-left: 0;
    padding-right: 0;
  }
  .navbar{
    margin-bottom: 0;
    border-radius: 0;
    border: none;
  }
  .btn-cart{
    font-family: $font-header;
    font-size: 16px;
    font-weight: normal;
    padding: 3px;
    .fa{
      display: none;
    }
    span>span{
      font-size: 12px;
    }
  }
  .logo{
    max-width: 160px;
    max-height: 90px;
    display: inline-block;
  }
  .search-top{
    width: 260px;
    position: relative;
    input{
      border: 1px solid transparent;
      border-radius: 3px !important;
      &::placeholder{
        font-size: 13px;
      }
      &:-moz-placeholder{
        font-size: 13px;
      }
      &:-ms-input-placeholder{
        font-size: 13px;
      }
      &::-moz-placeholder{
        font-size: 13px;
      }
      &::-webkit-input-placeholder{
        font-size: 13px;
      }
    }
    .open_search_js{
      display: none;
    }
    @media (min-width: 768px) and (max-width:991px){
      width: 30px;
      height: 30px;
      .search{
        position: absolute;
        width: 30px;
        right: 0;
        opacity: 0;
        -webkit-transition: width .3s;
        -moz-transition: width .3s;
        -ms-transition: width .3s;
        -o-transition: width .3s;
        transition: width .3s;
      }
      &.active{
        .search{
          width: 260px;
          opacity: 1;
          z-index: 100;
          input{
            z-index: 100;
            opacity: 1;
          }
        }
          .open_search_js{
            z-index: 0;
            display: none;
          }
        .close-mod{
          display: block;
          z-index: 99;
        }
      }
      input{
        opacity: 0;
      }
      .open_search_js{
        display: block;
      }
    }
    .close-mod{
      display: none;
    }
  }
  .close-mod{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .btn-search{
    position: absolute;
    z-index: 100;
    background: transparent;
    padding: 0 7px;
    right: 0;
    .fa{
      font-size: 13px;
    }
  }
  .col-header{
    padding: 35px 15px 30px 15px;
  }
  .col-logo{
    padding: 5px 15px;
    #logo{
      height: 85px;
      line-height: 85px;
      display: block;
    }
  }
  @media (min-width:767px) {
      .search-header {
        padding-right: 10px;
      }
    }
  

  // dropdown cart
  .dropdown-menu{
    border-radius: 2px;
    border: none;
  }
  .dropdown-cart{
    width: 345px;
    font-size: 13px;
    .thumb{
      width: 80px;
    }
    a{
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    .btn-remove{
      .fa{
        font-size: 13px;
      }
    }
    .btn{
      margin: 2px 8px;
      padding: 4px 7px;
      & + .btn{
        margin: 2px 5px;
      }
      &:hover{
        text-decoration: none;
      }
    }
    table{
      tr{
        &>td{
          &:first-child{
            padding-left: 15px;
          }
          &:last-child{
            padding-right: 4px;
          }
        }
      }
    }
    .total-text{
      font-size: 15px;
    }
    p{
      margin-bottom: 13px;
    }
    @media (max-width:767px) {
      max-width: 300px;
    }
  }
  //dop-menu-dropdown
  .dropdown-level-2{
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    .dropdown-menu-level-2{
      display: none;
      position: absolute;
      left: 100%;
      top: 0;
      background: #fff;
      margin-top: 0;
      @media (max-width:767px) {
        position: static;
        display: block;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
    &>.btn-menu{
      .fa{
        position: absolute;
        right: 15px;
        top: 12px;
        font-size: 12px;
        @media (max-width:767px) {
          display: none;
        }
      }
      @media (max-width:767px) {
        font-weight: 500;
      }
    }
    &:hover{
      .dropdown-menu-level-2{
        display: block;
      }
    }
  }
  .dop-menu-dropdown{
    max-width: 330px;
    min-width: 195px;
    margin-top: 8px;
    &>li{
      &>a{
        text-decoration: none;
        padding-top: 10px;
        padding-bottom: 10px;
        white-space: normal;
        &>span{
          font-size: 13px;
        }
      }
      .btn-menu{
        display: block;
        padding: 9px 20px;
        width: 100%;
        text-align: left;
        background-color: transparent;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .col-header{
    .menu-header{
      width: auto;
      display: inline-block;
      padding-top: 8px;
      &:after{
        width: 100%;
        height: 0;
        visibility: hidden;
        overflow: hidden;
        content: '';
        display: inline-block;
      }
      &>li{
        padding-right: 70px;
        padding-left: 3px;
        &:last-child {
          padding-right: 0;
        }
        @media (max-width: 1201px){
          padding-right: 10px;
        }
      }
      @media (min-width: 1201px){
        min-width: 400px;
        max-width: 570px;
      }
    }
    span.dropdown-toggle{
      text-decoration: underline;
      &:hover{
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  .col-menu{
    height: 36px;
  }
  .btn-menu{
    font-weight: 400;
    letter-spacing: 0;
  }
  @media (max-width:767px){
    #form-currency:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  // MOB HEADER
  .row-header{
    @media (max-width:767px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .search-header{
        order: 1;
      }
      .dropdown-menu-left{
        right: 0;
        left: auto;
      }
      .col-menu{
        flex: 1 1 100px;
        text-align: right;
      }
    }
  }
  .cart-header{
    //padding-top: 4px;
    @media (max-width:767px) {
      width: 100%;
      height:60px;
      padding: 15px;
      margin-bottom: 30px;
      position: fixed;
      top: 0;
      z-index: 1002;
      &>div:first-child{
        float: left;
      }
      &>div:last-child{
        float: right;
      }
      .fa{
        font-size: 32px;
      }
    }
    .cart-empty{
      padding-top: 10px;
    }
  }
  .search-header{
      //padding-top: 4px;
    @media (min-width: 768px) and (max-width:991px){
      padding-right: 0;
      padding-left: 0;
    }
    @media (max-width:767px) {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 30px;
      .search-top{
        width: 100%;
      }
    }
  }
  // MOB MENU
  @media (max-width:767px){
    .main-menu{
      position: fixed;
      z-index: 1001;
      top: 0;
      bottom: 0;
      width: 80%;
      margin-top: 60px;
      border-top: 0 !important;
      padding-top: 0;
      padding-bottom: 40px;
      overflow-x: hidden;
      left: -110%;
      -webkit-transition: .3s;
      -moz-transition: .3s;
      -ms-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      min-width: 260px;
      &.active{
        left: 0;
      }
      &>div, &>div>ul{
        margin-left: 0;
        margin-right: 0;
      }
      .navbar-nav{
        &>li{
          &>a, &>span{
            color: #fff;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-right: 55px;
            &:hover, &.active{
              text-decoration: underline;
            }
            &:after{
              display: none;
            }
            &.active{
              & + .mob-dropdown + .sub_menu{
                display: block;
                height: auto!important;
              }
            }
          }
          &.has-child{
            .sub_menu{
              margin-top: 0;
              position: relative;
              max-width: 100%;
              background: transparent;
              box-shadow: none;
              height: auto;
              display: none;
              padding: 0;
              -webkit-transition: inherit;
              -moz-transition: inherit;
              -ms-transition: inherit;
              -o-transition: inherit;
              transition: inherit;
              .sub_menu_contein{
                background: transparent;
              }
              a{
                color: #fff;
                &:hover, &.active{
                  text-decoration: underline;
                }
              }
            }
            .mob-dropdown{
              position: absolute;
              right: 0;
              top: 0;
              width: 35px;
              height: 40px;
              padding: 8px 0;
              text-align: center;
              font-family: FontAwesome;
              font-size: 18px;
              &.active, &.active.active-mob{
                &:before{
                  content: "\f106";
                }
              }
              &.active-mob:before, &:before{
                content: "\f107";
                display: inline;
              }
            }
            &:hover{
              .sub_menu, .sub_menu_contein{
                padding-top: 0;
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
    #menu-btn{
      &.active{
        .fa{
          &:before{
            content: '\f060';
          }
        }
      }
    }
    .close-menu{
      position: fixed;
      top: 0;
      left: 0;
      width: 60px;
      padding: 13px 15px;
      z-index: 99;
      display: none;
      &:hover{
        cursor: pointer;
        .fa{
          opacity: .8;
        }
      }
      .fa{
        font-size: 32px;
      }
    }
  }
  .close-menu{
    display: none !important;
  }

  /* Header */

  /* Slider */
    .slider-cut {
        width: 100%;
        overflow: hidden;
        position: relative;   
      .item {
        width: 100%;
        overflow: hidden;
        position: relative;
          img {
            position: absolute;
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            max-width: 1000%;
            max-height: 1000%;
            transform: translateY(-50%) translateX(-50%);
            left: 50%;
            top: 50%;
        }
      }   
    }
  /* Slider */

  /* Schema module */
  .schemes-header {
    position: relative;
    padding-right: 50px;
    .schemes-container {
      position: absolute;
      top: 5px;
      right: 0;
      padding-top: 35px;
      padding-bottom: 30px;
      .schemes {
        display: inline-block;
        cursor: pointer;
        &:before {
          content: url($url + "schema.png");
          display: inline-block;
          vertical-align: middle;
          font-style: normal;
        }
      }    
       ul {
          min-width: 180px;
          margin-top: 15px;
          cursor: pointer;
          padding: 0;
          li {
            padding: 10px;
            cursor: pointer;
          }
        }
      }
      @media (max-width: 767px){
         padding-right: 40px;
        .schemes-container {
           .dropdown-menu {
          left: -70px;
          }
        } 
      }
    }
    
    /* Schema module */

}
  
